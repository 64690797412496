import {SessionListPage} from "./pages/session_list";
import {SessionPage} from "./pages/session_page";
import {HashRouter as Router, Routes, Route} from "react-router-dom";

import './App.css';

function App() {

    return (
        <div className="App">
            <Router>
                <Routes>
                    {/*A list of the page paths and the objects that create them*/}
                    <Route path={"/"} element={<SessionListPage/>}></Route>
                    <Route path={"/session/:id"} element={<SessionPage/>}></Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
