import {Link, useParams} from "react-router-dom";
import {GetResultsTableFromUUID} from "../components/sess_uuid_data";

function HeadItems(head) {
    return (<th>{head}</th>)
}

function BodyItems(body) {
    return (<td>{body}</td>)
}

function MyHeadings(headers) {

    console.log("C", headers)

    const html = headers.map(h => HeadItems(h));

    return (
        <tr>
            {html}
        </tr>
    )
}

function MyItem(record) {

    const html = record.map(v => BodyItems(v));

    console.log(record)
    console.log(html)

    return (
        <tr>
            {html}
        </tr>
    )
}

function MyTable(resultsTable) {

    let headers = resultsTable[0]
    let records = resultsTable.slice(1, resultsTable.length)

    let headings = MyHeadings(headers)
    const data_items = records.map(record => MyItem(record));

    return (
        <div className="App">
            <table>
                <tbody>
                {headings}
                {data_items}
                </tbody>
            </table>
        </div>
    );
}

export function SessionPage() {

    const {id} = useParams()
    let allResultsTable = GetResultsTableFromUUID(id)
    if (allResultsTable == null) {
        return <></>
    }

    let resultsTable = allResultsTable[1]
    let sess_name = allResultsTable[0]

    console.log("SN", sess_name)
    console.log("RT", resultsTable)

    const table = MyTable(resultsTable)

    return (
        <div style={PageStyle}>
            <div style={HeaderStyle}>
                <Link to={"/"} style={HeaderLeftStyle}>
                    <button style={ButtonStyle}>&lt; Back</button>
                </Link>
                <div style={TitleStyle}>{sess_name}</div>
                <div style={HeaderRightStyle}></div>
            </div>
            <div style={BodyStyle}>
                {table}
            </div>
        </div>
    )
}

const HeaderStyle = {
  display: 'flex',
  flexDirection: 'row',
  margin: "10px",
  justifyContent: 'space-between',
  alignItems: 'center',
}

const PageStyle = {
}

const TitleStyle = {
    fontSize: '18px',
    fontWeight: 'bold',
}

const HeaderLeftStyle = {
    flex:1,
    }
const HeaderRightStyle = {
    flex:1
    }

const BodyStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

const ButtonStyle = {
    fontSize: '18px',
    color: '#CCFF33',
    backgroundColor: '#339966',
    padding: "10px"
}
