import {useEffect, useState} from "react";


// get the json data from the file results_index.json
function GetResultsIndex() {
    const [responseData, setResponseData] = useState(null);
    useEffect(() => {
        (async () => {
            try {
                let index_file = "data/results_index.json"
                const response = await fetch(index_file);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setResponseData(data);
            } catch (error) {
                console.error("Error:", error.message);
            }
        })();
    }, []);

    return responseData
}

// get the list of UUIDs in the file results_index.json
export function GetIndexList(reverse=false) {

    let resultsIndex = GetResultsIndex()
    if (resultsIndex == null)
        return []

    if (reverse) {
        return (resultsIndex.uuids)
        }
    else {
        return resultsIndex.uuids
        }
}

function GetResultsData(uuid) {

    const [responseData, setResponseData] = useState(null);
    useEffect(() => {
        (async () => {
            try {
                let results_file = "data/results/" + uuid + ".json"
                const response = await fetch(results_file);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                setResponseData(data);
            } catch (error) {
                console.error("Error:", error.message);
            }
        })();
    }, [uuid]);

    return responseData
}

export function GetResultsTableFromUUID(uuid) {

    let resultsData = GetResultsData(uuid)
    if (resultsData == null)
        return null

    let allResultsTable = [resultsData.sess_name, resultsData.str_records]
    return allResultsTable
}

