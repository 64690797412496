import {Link} from "react-router-dom";
import {GetIndexList} from "../components/sess_uuid_data"

function MyElem(index) {

    let uuid = index[0]
    let sess_name = index[1]

    return (
        <div key={uuid}>
            <Link to={"/session/" + uuid}>
                <button style={ButtonStyle}>{sess_name}</button>
            </Link>
            <p></p>
        </div>
    )
}

export function SessionListPage() {

    let indexList = GetIndexList(true)
    var listItems = indexList.map(index => MyElem(index));

    return (
        <div style={PageStyle}>
            <h3>Session Results...</h3>
            {listItems}
        </div>
    );
}


const PageStyle = {
    fontSize: '24px',
}

const ButtonStyle = {
    height:'80px',
    width:'300px',
    fontSize: '24px',
    color: '#CCFF33',
    backgroundColor: '#339966',
}